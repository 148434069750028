
body {
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, rgb(50, 49, 31) 0%, rgb(71, 17, 56) 52%, rgb(23, 50, 64) 100%);
  margin: 0;
  padding: 0;
}

.technologies {
  padding: 20px;
  text-align: center;
}

.technologies h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ffffff;
}

.technology-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.technology-item {
  flex: 1 1 300px;
  max-width: 300px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden; /* Ensure the image doesn't overflow the card */
}

.technology-item:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.technology-image-container {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 15px;
}

.technology-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.technology-item:hover .technology-image {
  transform: scale(1.2); /* Zoom the image */
}


.technology-content h3 {
  font-size: 1.5em;
  margin: 0;
  color: #fff;
}

.technology-content p {
  font-size: 1em;
  color: #ddd;
}

