.top-header {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
  padding: 5px 20px;
  background: linear-gradient(90deg, rgb(195, 193, 193) 0%, rgb(204, 202, 202) 50%, rgba(200,200,200,1) 100%);  color: #333; /* Darker text color for contrast */
  font-size: 0.9em;
}

.top-header div {
  margin-left: 20px; /* Add space between email and phone number */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 50%, rgba(200,200,200,1) 100%);
  color: #333; /* Darker text color for contrast */
  position: relative;
}

.logo a {
  color: #333; /* Darker text color for contrast */
  text-decoration: none;
  font-size: 1.5em;
}

.logo-image {
  height: 80px;
  width: 80px;
  margin-left: 40px;
  margin-bottom: -10px;
  margin-top: -5px;
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 0 10px;
}

.nav a {
  color: #333; /* Darker text color for contrast */
  text-decoration: none;
  font-size: 1em;
}

.nav a:hover {
  text-decoration: underline;
}

.nav .active a {
  font-weight: bold;
  text-decoration: underline;
}

.menu-toggle {
  display: none;
  font-size: 1.5em;
  background: none;
  border: none;
  color: #333; /* Darker text color for contrast */
  cursor: pointer;
  position: absolute;
  right: 45px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .top-header {
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
  }

  .top-header div {
    margin-left: 0;
    margin-bottom: 5px; /* Add space between email and phone number */
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
    display: none;
    margin-top: 25px;
  }

  .nav.open ul {
    display: flex;
  }

  .nav li {
    margin: 5px 0;
  }

  .menu-toggle {
    display: block;
  }
}
