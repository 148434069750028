body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.timeline {
  position: relative;
  width: 80%;
  margin: 50px auto;
  padding: 20px 0;
}

.timeline-line {
  position: absolute;
  left: 50%;
  width: 4px;
  height: 100%;
  background: #ddd;
  transform: translateX(-50%);
}

.timeline-event {
  position: relative;
  width: 45%;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-event.left {
  left: 0;
  transform: translateX(0);
}

.timeline-event.right {
  left: 50%;
  transform: translateX(0);
}

.timeline-event.left::before,
.timeline-event.right::before {
  content: '';
  position: absolute;
  top: 20px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline-event.left::before {
  left: 100%;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ddd transparent transparent;
}

.timeline-event.right::before {
  right: 100%;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ddd;
}

.timeline-date {
  font-size: 1em;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.timeline-text {
  text-align: center;
}

.timeline-text h3 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #333;
}

.timeline-text p {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}