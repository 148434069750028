/* src/components/Footer.css */


#footer {
  background: linear-gradient(90deg, rgb(85, 203, 250) 0%, rgb(55, 196, 55) 100%);
  color: white;
  padding: 20px 0;
  margin-top: -5px;
}

.container-footer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-4, .col-sm-6 {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
}

.footer-link-headings {
  font-size: 18px;
  margin-bottom: 10px;
}

.foo-head-fix {
  margin-top: 0;
}

.list-unstyled {
  list-style-type: none;
  padding: 0;
}

.quick-link-items {
  color: white;
  text-decoration: none;
}

.quick-link-items:hover {
  text-decoration: underline;
}

.contact-link {
  color: white;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.line-break-bottom {
  border-top: 1px solid #555;
  width: 100%;
  margin: 20px 0;
}

.footer-text {
  margin-left: 20px;
}

.text-right {
  text-align: right;
}

.social-icon {
  color: white;
  text-decoration: none;
  margin: 0 5px;
}

.social-icon i {
  font-size: 15px;
  border: 1px solid white;
  padding: 8px;
  border-radius: 50%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .text-right {
    text-align: center;
    margin-top: 20px;
  }

  .footer-text {
    text-align: center;
    margin: 0;
  }
}