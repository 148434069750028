/* General Container */
.container {
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the container */
  padding: 0 20px; /* Add some space on the left and right */
}

/* About Us Section */
.about-us {
  padding: 50px 0;
  background-color: #ffffff;
  color: #000000;
  text-align: center; /* Center text */
}

.about-us h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.about-us img {
  max-width: 100%;
  height: auto;
}

/* Vision and Mission Section */
.vision-mission-section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vision, .mission {
  display: flex;
  align-items: center;
  background: #dddcdc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: #000000;
}

.vision .vision-image, .mission .mission-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

.vision .vision-image {
  background-image: url('../images/vision.jpg');
}

.mission .mission-image {
  background-image: url('../images/mission.jpg');
}

.vision .vision-text, .mission .mission-text {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.vision i, .mission i {
  font-size: 2rem;
  margin-bottom: 10px;
}

.vision h2, .mission h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.vision p, .mission p {
  font-size: 1rem;
}

/* Team Section */
.team-section {
  margin-top: 50px;
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  flex-direction: column; /* Optional: if you want the content to stack vertically */
  text-align: center; /* Center text */
}

.team-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #ffffff;
}

.team-member {
  text-align: center;
  margin-bottom: 30px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.team-member:hover {
  transform: scale(1.05);
}

.team-photo {
  height: 150px;
  width: 150px;
  background: #ccc;
  border-radius: 50%;
  margin: 0 auto 15px;
  overflow: hidden;
  position: relative;
}

.team-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.team-member p {
  font-size: 1rem;
  color: #aaa;
}

/* Testimonials Section */
.testimonials-section {
  margin-top: 50px;
  text-align: center; /* Center text */
}

.testimonials-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.testimonial {
  background: #a69d9d;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.testimonial p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.testimonial h4 {
  font-size: 1rem;
  color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-us .col-md-6 {
    width: 100%;
  }

  .team-member {
    margin-bottom: 20px;
  }

  .vision, .mission {
    flex-direction: column;
  }

  .vision .vision-image, .mission .mission-image {
    min-height: 200px;
  }
}

.image-placeholder {
  height: 200px;
  width: 1200px; /* Make the width 100% of its container */
  max-width: 1200px; /* Set a maximum width */
  margin: 0 auto; /* Center the container */
  background: linear-gradient(90deg, rgba(56,110,136,1) 0%, rgba(195,29,155,1) 52%, rgba(10,128,185,1) 100%);
}

/* Media query for extra small screens */
@media (max-width: 480px) {
  .image-placeholder {
    height: 120px; /* Adjust height for extra small screens */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
  }
}

/* Media query for small screens */
@media (min-width: 481px) and (max-width: 600px) {
  .image-placeholder {
    height: 150px; /* Adjust height for small screens */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
  }
}

/* Media query for small to medium screens */
@media (min-width: 601px) and (max-width: 768px) {
  .image-placeholder {
    height: 160px; /* Adjust height for small to medium screens */
    max-width: 95%; /* Adjust max-width for small to medium screens */
  }
}

/* Media query for medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-placeholder {
    height: 180px; /* Adjust height for medium screens */
    max-width: 90%; /* Adjust max-width for medium screens */
  }
}

/* Media query for large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .image-placeholder {
    height: 200px; /* Default height for large screens */
    max-width: 85%; /* Adjust max-width for large screens */
  }
}

/* Media query for extra large screens */
@media (min-width: 1201px) {
  .image-placeholder {
    height: 200px; /* Default height for extra large screens */
    max-width: 1200px; /* Default max-width for extra large screens */
  }
}
