/* General Styles */
.larger-font {
  font-size: 60px; /* Adjust the size as needed */
}

.static-slide {
  position: relative;
  width: 100%;
  margin: auto;
  border: none; /* Make the slide borderless */
  overflow: hidden; /* Ensure no overflow */
  margin-bottom: 0; 
}

.static-slide img {
  width: 100%;
  height: auto;
  max-height: 100vh; /* Ensure the image does not exceed the viewport height */
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.imageprop {
  opacity: 0.8;
}

/* Custom Styles */
.d-green {
  background-color: #28a745;
}

.L-green {
  background-color: #20c997;
}

.mobile-img {
  width: 100%;
  height: auto;
}

.primary-heading {
  font-size: 2rem;
  font-weight: bold;
}

.sub-head,
.sub-head1,
.sub-head2,
.sub-head3 {
  font-size: 1.2rem;
  margin: 5px 0;
}

.main-para {
  font-size: 1.5rem;
  margin: 10px 0;
}

.secondary-heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.line {
  display: inline-block;
  background-color: #1d1a1a;
  margin: 0 10px;
}

/* Position text box on the left side */
.text-box {
  position: absolute;
  left: 5%;
  top: 40%;
  transform: translateY(-50%);
  text-align: left;
}

/* src/css/carousel.css */
@keyframes flyIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fly-in {
  opacity: 0; /* Ensure text is initially hidden */
  animation: flyIn 1s ease-out forwards;
}

.line-0 {
  animation-delay: 0s;
}

.line-1 {
  animation-delay: 1s;
}

.line-2 {
  animation-delay: 2s;
}

.line-3 {
  animation-delay: 3s;
}

.line-4 {
  animation-delay: 4s;
}

.image-dimension {
  height: 515px;
  width: 1920px;
  object-fit: cover;
  background: linear-gradient(90deg, rgba(56,110,136,1) 0%, rgba(195,29,155,1) 52%, rgba(10,128,185,1) 100%);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .larger-font {
    font-size: 50px;
  }
  .primary-heading {
    font-size: 1.8rem;
  }
  .main-para {
    font-size: 1.3rem;
  }
  .secondary-heading {
    font-size: 1.6rem;
  }
}

@media (max-width: 992px) {
  .larger-font {
    font-size: 40px;
  }
  .primary-heading {
    font-size: 1.6rem;
  }
  .main-para {
    font-size: 1.2rem;
  }
  .secondary-heading {
    font-size: 1.4rem;
  }
  .carousel-caption {
    right: 10%;
    left: 10%;
  }
}

@media (max-width: 768px) {
  .larger-font {
    font-size: 30px;
  }
  .primary-heading {
    font-size: 1.4rem;
  }
  .main-para {
    font-size: 1rem;
  }
  .secondary-heading {
    font-size: 1.2rem;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .text-box {
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 576px) {
  .larger-font {
    font-size: 20px;
  }
  .primary-heading {
    font-size: 1.2rem;
  }
  .main-para {
    font-size: 0.9rem;
  }
  .secondary-heading {
    font-size: 1rem;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .text-box {
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* carousel.css */
body {
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
  margin: 20px;
}

.large-text {
  font-size: 45px; /* Adjust the size as needed */
  margin-bottom: -5px; /* Adjust the gap between paragraphs as needed */
  padding: 0;
}

.small-text {
  font-size: 20px; /* Adjust the size as needed */
  margin-bottom: 5px; /* Adjust the gap between paragraphs as needed */
  padding: 0;
}

.text-box p + p {
  margin-top: 10px; /* Adjust the gap between paragraphs as needed */
}

/* Wave Effect */

.wave-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px; /* Adjust the height as needed */
  overflow: hidden;
  z-index: 5; /* Ensure it appears above the image */
}

.wave {
  position: absolute;
  bottom: 0;
  width: 200%;
  height: 40%;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg');
  background-size: 50% 100%;
  opacity: 0.9;
  animation: wave 10s linear infinite; /* Adjust the duration as needed */
  filter: hue-rotate(350deg) saturate(350%); /* Adjust these values to change the color */
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


/* carousel.css */
.carousel-container {
  width: 600px; /* Adjust the width as needed */
  margin-top: -40px; /* Adjust the margin as needed */
  position: relative;
}

input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  margin-top: -230px;
  margin-left: 600px ;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.imgProducts {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius:20px;
}

#item-1:checked ~ .cards #product-5, #item-2:checked ~ .cards #product-1, #item-3:checked ~ .cards #product-2, #item-4:checked ~ .cards #product-3, #item-5:checked ~ .cards #product-4 {
  transform: translatex(-40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #product-2, #item-2:checked ~ .cards #product-3, #item-3:checked ~ .cards #product-4, #item-4:checked ~ .cards #product-5, #item-5:checked ~ .cards #product-1 {
  transform: translatex(40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #product-1, #item-2:checked ~ .cards #product-2, #item-3:checked ~ .cards #product-3, #item-4:checked ~ .cards #product-4, #item-5:checked ~ .cards #product-5 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
  
  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}

/* Position text box on the left side */
.text-box {
  position: absolute;
  left: 5%;
  top: 40%;
  transform: translateY(-50%);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
