/* General Styles */
.page-container {
  text-align: center;
  padding: 20px;
  background: #e0f7fa; /* Light cyan background */
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.title {
  background: linear-gradient(135deg, #00796b, #004d40); /* Gradient background */
  color: #ffffff;
  padding: 40px 0;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  animation: fadeIn 2s ease-out;
}

.product-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-row:nth-child(odd) {
  animation: slideInFromLeft 1.5s ease-out;
}

.product-row:nth-child(even) {
  animation: slideInFromRight 1.5s ease-out;
}

@keyframes slideInFromLeft {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

@keyframes slideInFromRight {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.product-row:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  flex: 1;
  padding: 10px;
  text-align: center;
  animation: zoomIn 1s ease-out;
}

@keyframes zoomIn {
  from { transform: scale(0.5); }
  to { transform: scale(1); }
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(1.1);
}

.product-description {
  flex: 2;
  padding: 20px;
  text-align: left;
  color: #004d40;
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
  .product-row {
    flex-direction: column;
    text-align: center;
  }

  .product-description {
    text-align: center;
  }

  .title {
    font-size: 90%;
  }

  .product-description h2 {
    font-size: 20px;
  }

  .product-description p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .title {
    padding: 30px 0;
  }

  .product-description h2 {
    font-size: 18px;
  }
}

.categories {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.category-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #004d40;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.category-button:hover {
  background-color: #00796b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.category-button.active {
  background-color: #d32f2f;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
