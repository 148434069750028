body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.contact-us {
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-us__location {
  margin-bottom: 40px;
  padding: 20px;
  background: linear-gradient(90deg, #c8eafb 0%, #f6dcf0 52%, #e5f0f6 100%);
  border-radius: 8px;
}

.contact-us__location h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.contact-us__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-us__address {
  width: 35%;
  font-size: 1em;
  color: #333; /* Slightly darker for better readability */
  line-height: 1.6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Subtle shadow */
  border-radius: 12px; /* Slightly more rounded corners */
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Gradient background */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; /* Smooth transitions */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow for depth */
  position: relative; /* For pseudo-element positioning */
  overflow: hidden; /* To contain pseudo-element */
}

.contact-us__address::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-25deg);
  transition: left 0.5s ease;
}

.contact-us__address:hover::before {
  left: 100%;
}

.contact-us__address:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.08); /* Enhanced shadow on hover */
  background: linear-gradient(135deg, #e2ebf0, #a1b8d1); /* Slightly different gradient on hover */
}

.contact-us__address p {
  margin: 5px 0;
}

.contact-us__address p:first-child {
  font-size: 1.5em;
  color: #333;
  font-weight: bold;
}

.contact-us__address p:nth-child(2) {
  font-size: 1.2em;
  color: #00796b;
  font-weight: bold;
}

.contact-us__address p:nth-child(3),
.contact-us__address p:nth-child(4),
.contact-us__address p:nth-child(5),
.contact-us__address p:nth-child(6) {
  font-size: 1em;
  color: #666;
}

.contact-us__address p:nth-child(7) {
  font-size: 1.1em;
  color: #333;
  font-weight: bold;
}

.contact-us__address p:nth-child(8) {
  font-size: 1em;
  color: #00796b;
  text-decoration: underline;
}

.contact-us__map {
  width: 60%;
  margin-left: 20px;
  border: 2px solid #000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 8px;
}

.contact-us__separator {
  height: 2px;
  background-color: #00796b;
  margin: 40px 0;
}

.contact-us__form {
  margin-bottom: 40px;
}

.contact-us__form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.contact-us__form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  width: 48%;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

button[type="submit"] {
  width: 100%;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #26bcee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}