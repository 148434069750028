body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.faq {
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.faq-question {
  padding: 15px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question:hover {
  background-color: #e9e9e9;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 15px;
  background-color: #f4f4f9;
  border-radius: 8px;
}

.faq-answer.active {
  max-height: 200px;
  padding: 15px;
}

.faq-answer p {
  margin: 0;
  font-size: 1em;
  color: #666;
}

.arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.arrow.right {
  transform: rotate(45deg);
}

.arrow.down {
  transform: rotate(135deg);
}